import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Sidebar from "../sidebar/Sidebar";
import { Helmet } from "react-helmet";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./requestDetails.css";

const isValidTechReport = (techReport) => techReport.trim() !== "";

const RequestDetails = () => {
  const params = useParams();
  const [requestDetails, setRequestDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [techReport, setTechReport] = useState("");
  const [techReportError, setTechReportError] = useState("");

  const imageRef = useRef(null);

  const url = `https://aircon-api.basilalasadi.com/api/maintenance/AllRequestsReportForTechnician`;

  const getRequestDetails = async () => {
    try {
      const maintenanceAreaIdsString =
        localStorage.getItem("MaintenanceAreaIds");
      const maintenanceAreaIds = maintenanceAreaIdsString
        .split(",")
        .map(Number);
      const response = await axios.post(url, {
        maintenanceAreaIds: maintenanceAreaIds,
        RequestId: params.inProgressId,
      });
      setRequestDetails(response.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });

  const submit = async (e) => {
    e.preventDefault();
    const techReportIsValid = isValidTechReport(techReport);
    setTechReportError(techReportIsValid ? "" : "برجاء كتابة تقرير الصيانه");
    if (techReportIsValid) {
      try {
        const technicianId = localStorage.getItem("UserId");
        const url =
          "https://aircon-api.basilalasadi.com/api/maintenance/TechnicianAddsHisNote";
        await axios.post(url, {
          TechnicianNotes: techReport,
          RequestId: params.inProgressId,
          technicianId: technicianId,
        });
        setTechReport("");
      } catch (err) {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    getRequestDetails();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <section className="requestDetails">
      <Helmet>
        <meta charSet="utf-8" />
        <title>تفاصيل الطلب </title>
      </Helmet>
      {loading ? (
        <div className="spinner-container">
          <ClipLoader
            className="loader"
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            color="#00b1ff"
          />
        </div>
      ) : (
        <div className="requestDetails-container">
          <div className="row">
            <div className="col-lg-2 col-md-2">
              <Sidebar />
            </div>
            <div
              className="col col-lg-10 col-md-8 d-flex"
              ref={componentRef}
              style={{
                width: "100vw",
              }}
            >
              <div className="col-lg-6">
                {requestDetails.map((request) => (
                  <div className="data-container" key={request.RequestId}>
                    <h2>تفاصيل الطلب</h2>
                    <div className="clientDetails">
                      <h5 className="title my-4">بيانات العميل</h5>
                      <div className="d-flex mb-4 largeBox">
                        <div className="box d-flex mx-3">
                          <h5>إسم العميل:</h5>
                          <h6>{request.CustomerName}</h6>
                        </div>
                        <div className="box d-flex mx-3">
                          <h5>المدينة:</h5>
                          <h6>{request.City}</h6>
                        </div>
                      </div>
                      <div className="d-flex mb-4">
                        <div className="box d-flex mx-3">
                          <h5>المنطقة:</h5>
                          <h6>{request.Region}</h6>
                        </div>
                        <div className="box d-flex mx-3">
                          <h5>الحي:</h5>
                          <h6>{request.Neighborhood}</h6>
                        </div>
                      </div>
                      <div className="d-flex mb-4">
                        <div className="box d-flex mx-3">
                          <h5>رقم الهاتف:</h5>
                          <h6>
                            <a
                              href={`https://api.whatsapp.com/send?phone=+966${request.PhoneNumber.replace(
                                /[^\d+]/g,
                                ""
                              )}&text=Hello%2C%20I'm%20interested%20in%20your%20product`}
                              target="__blank"
                            >
                              {request.PhoneNumber}
                            </a>
                          </h6>
                        </div>
                        <div className="box d-flex mx-3">
                          <h5>رقم الطلب:</h5>
                          <h6>{request.FormattedRequestNumber}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="ACdetails">
                      <h5 className="title my-4">بيانات المكيف</h5>
                      <div className=" mb-4 largeBox">
                        <div className="box d-flex mx-3">
                          <h5>نوع المكيف:</h5>
                          <h6>{request.ACType}</h6>
                        </div>
                        <div className="box d-flex mx-3">
                          <h5>حالة الضمان:</h5>
                          <h6>{request.WarrantyStatusName}</h6>
                        </div>
                        <div className="box d-flex mx-3">
                          <h5>الملاحظات:</h5>
                          <h6>{request.ErrorType}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="clientDetails">
                      <h5 className="title my-4">بيانات الصيانة</h5>
                      <div className="d-flex mb-4 largeBox">
                        <div className="box d-flex">
                          <h5>تاريخ حجز الطلب:</h5>
                          <h6>{request.SubmissionDate}</h6>
                        </div>
                        <div className="box d-flex ">
                          <h5>تاريخ الصيانة:</h5>
                          <h6>{request.MaintenanceStartDate}</h6>
                        </div>
                      </div>
                      <div className="d-flex mb-4">
                        <div className="box d-flex">
                          <h5>الفني المسؤول:</h5>
                          <h6>{request.TechnicianName}</h6>
                        </div>
                        <div className="box d-flex ">
                          <h5>تقرير الفني:</h5>
                          <h6>{request.TechnicianNotes}</h6>
                        </div>
                      </div>
                      <div className="d-flex mb-4">
                        <div className="box d-flex ">
                          <h5>قطع الغيار المستخدمه:</h5>
                          <h6>{request.AirCondMaintPartsStr}</h6>
                        </div>
                        <div className="box d-flex">
                          <h5>حالة الطلب :</h5>
                          {request.CurrentStatus === "طلبات جديده" ? (
                            <p className="newReq">جديد</p>
                          ) : request.CurrentStatus ===
                            "الطلبات التي تم الموافقه عليها" ? (
                            <p className="approvedReq">تحت المراجعه</p>
                          ) : request.CurrentStatus === "الطلبات المرفوضه" ? (
                            <p className="rejectedReq">مرفوض</p>
                          ) : request.CurrentStatus ===
                            "المواعيد التي تم جدولتها" ? (
                            <p className="inProgress">جاري التنفيذ</p>
                          ) : request.CurrentStatus ===
                            "المواعيد التي تم إلغائها" ? (
                            <p className="rejectedReq">تم إلغاء الطلب</p>
                          ) : (
                            <p className="maintainceDone">تم تنفيذ الطلب</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-lg-4 col-md-8">
                <div className="img-holder">
                  <h5>الفاتوره</h5>
                  <TransformWrapper
                    defualtScal={1}
                    defualtPositionX={100}
                    defualtPositionY={200}
                  >
                    <TransformComponent>
                      {requestDetails.length > 0 &&
                        requestDetails[0].ImagePath && (
                          <>
                            <img
                              className="imageDetails"
                              src={`${requestDetails[0].ImagePath}`}
                              alt="Receipt"
                              ref={imageRef}
                              style={{ height: "400px" }}
                            />
                          </>
                        )}
                    </TransformComponent>
                  </TransformWrapper>
                </div>
                <form onSubmit={submit} className="techReportFrom">
                  <textarea
                    placeholder="اكتب تقرير الصيانه..."
                    onChange={(e) => setTechReport(e.target.value)}
                    value={techReport}
                  ></textarea>
                  {techReportError && (
                    <p className="text-danger">{techReportError}</p>
                  )}
                  <button className="w-100" type="submit">
                    ارسال التقرير
                  </button>
                </form>
                <button className="w-100 mt-4" onClick={handlePrint}>
                  طباعه التقرير
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default RequestDetails;
