import React, { useEffect, useState } from "react";
import {
  Chart as ChartJs,
  LinearScale,
  CategoryScale,
  BarElement,
} from "chart.js";
import datalabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import "./technincan.css";

ChartJs.register(CategoryScale, BarElement, LinearScale);
ChartJs.register(datalabels);

const TechnicianChart = () => {
  const [chart, setChart] = useState([]);

  var baseUrl =
    "https://aircon-api.basilalasadi.com/api/Reports/GetTechnicianRequestCounts";

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const maintenanceAreaIdsString =
          localStorage.getItem("MaintenanceAreaIds");
        const maintainceAreaId = maintenanceAreaIdsString
          .split(",")
          .map(Number);
        const response = await axios.post(
          baseUrl,
          {
            maintenanceAreaIds: maintainceAreaId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        setChart(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchInfo();
  }, [baseUrl]);

  var data = {
    labels: chart?.map((x) => x.TechnicianName),
    datasets: [
      {
        label: ` اعمال الفنيين`,
        data: chart?.map((x) => x.RequestCount),
        backgroundColor: [
          "#FFA3B6",
          "#FFAA57",
          "#FFD97E",
          "#8BD7D7",
          "#91CCF4",
          "#4072EE",
          "#29CB97",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "عدد أعمال الفنيين",
        },
      },
    },
    legend: {
      labels: {
        fontSize: 26,
      },
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "start",
        color: "black",
      },
    },
  };

  return (
    <div className="warrantyChart">
      <div className="warrantyChart-container container">
        <div>
          <h2>أعمال الفنيين</h2>
        </div>
        <div>
          <Bar data={data} height={400} options={options} />
        </div>
      </div>
    </div>
  );
};

export default TechnicianChart;
