export const team = [
  {
    id: 1,
    avatar: require("../../assets/Technicians/5.jpg"),
    techName: "محمد محسن",
  },
  {
    id: 2,
    avatar: require("../../assets/Technicians/4.jpg"),
    techName: "محمد ناجي",
  },
  {
    id: 3,
    avatar: require("../../assets/Technicians/3.jpg"),
    techName: "محمد أمين",
  },
  {
    id: 4,
    avatar: require("../../assets/Technicians/2.jpg"),
    techName: "محمد يونس",
  },
  {
    id: 5,
    avatar: require("../../assets/Technicians/1.jpg"),
    techName: "محمد عثمان",
  },
];
