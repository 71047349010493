import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import Sidebar from "../../components/sidebar/Sidebar";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./technicalScreen.css";
import { useReactToPrint } from "react-to-print";

const TechnicalScreen = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [technicianInfo, setTechnicianInfo] = useState([]);

  const getAllAppointments = async () => {
    try {
      const maintenanceAreaIdsString =
        localStorage.getItem("MaintenanceAreaIds");
      const maintenanceAreaIds = maintenanceAreaIdsString
        .split(",")
        .map(Number);
      var url =
        "https://aircon-api.basilalasadi.com/api/maintenance/GetTechniciansNotApproved";
      await axios
        .post(url, {
          maintenanceAreaIds: maintenanceAreaIds,
        })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const accountApproval = async (item) => {
    try {
      const parsedData = JSON.parse(JSON.stringify(item));
      const technicianId = parsedData.TechnicianId;
      await axios.post(
        "https://aircon-api.basilalasadi.com/api/maintenance/ApprovingAccountsForTechnicians",
        {
          TechnicianId: `${technicianId}`,
        }
      );
      toast.success("تم قبول الحساب بنجاح", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if (setData) {
        setData(data.filter((d) => d.TechnicianId !== item.TechnicianId));
      } else {
        console.warn(
          "setData function is not defined. Local data update skipped."
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const rejectAccount = async (item) => {
    try {
      const parsedData = JSON.parse(JSON.stringify(item));
      const technicianId = parsedData.TechnicianId;
      await axios.post(
        "https://aircon-api.basilalasadi.com/api/maintenance/RejectingTechniciansAccounts",
        {
          TechnicianId: `${technicianId}`,
        }
      );
      toast.success("تم رفض الحساب", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if (setData) {
        setData(data.filter((d) => d.TechnicianId !== item.TechnicianId));
      } else {
        console.warn(
          "setData function is not defined. Local data update skipped."
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GetTechnicianInfo = async () => {
    try {
      const maintenanceAreaIdsString =
        localStorage.getItem("MaintenanceAreaIds");
      const maintenanceAreaIds = maintenanceAreaIdsString
        .split(",")
        .map(Number);
      var url =
        "https://aircon-api.basilalasadi.com/api/Reports/TechnicianReport";
      await axios
        .post(url, {
          maintenanceAreaIds: maintenanceAreaIds,
        })
        .then((response) => {
          setTechnicianInfo(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });

  useEffect(() => {
    getAllAppointments();
    GetTechnicianInfo();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <section className="technicalScreen mt-0">
      <Helmet>
        <meta charSet="utf-8" />
        <title> مسئولين الصيانه </title>
        <link
          rel="canonical"
          href="https://generalhome.sa/dashboard/technicalScreen"
        />
      </Helmet>
      {loading ? (
        <div className="spinner-container">
          <ClipLoader
            className="loader"
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            color="#00b1ff"
          />
        </div>
      ) : (
        <div className="technicalScreen-container">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 mb-5">
              <Sidebar data={data} />
            </div>
            {/* technical Information */}
            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 pt-4 mb-5">
              <div className="head d-flex justify-content-between">
                <h2>بيانات مسئولين الصيانه:</h2>
                <button className="printButton" onClick={handlePrint}>
                  طباعه التقرير
                </button>
              </div>
              <div className="table-responsive-sm" ref={componentRef}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="row">الاسم</th>
                      <th scope="row" className="text-center">
                        طلبات قيد التنفيذ
                      </th>
                      <th scope="row" className="text-center">
                        طلبات تم تنفيذها
                      </th>
                      <th scope="row" className="text-center">
                        التحكم
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {technicianInfo.map((item, index) => (
                      <tr key={index}>
                        <td>{item.TechnicianName}</td>
                        <td className="text-center">
                          {item.NumberOfScheduledProjects}
                        </td>
                        <td className="text-center">
                          {item.NumberOfDoneProjects}
                        </td>
                        <td className="text-center d-flex justify-content-center buttons-box">
                          <Link
                            className="link"
                            to={`/dashboard/scheduledProjects/${item.TechnicianId}`}
                          >
                            <button> طلبات قيد التنفيذ</button>
                          </Link>
                          <Link
                            className="link"
                            to={`/dashboard/doneProjects/${item.TechnicianId}`}
                          >
                            <button>طلبات تم تنفيذها </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* Pending Accounts */}
              <div className="pendingAcc pt-5">
                <h2>الحسابات المعلقه :</h2>
                <div className="table-responsive-sm">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="row">الاسم</th>
                        <th scope="row">رقم الهاتف</th>
                        <th scope="row" className="text-center">
                          التحكم
                        </th>
                      </tr>
                    </thead>
                    {data?.length > 0 ? (
                      <tbody>
                        {data.map((item) => (
                          <tr key={item.TechnicianId}>
                            <td>{item.Username}</td>
                            <td>
                              <a
                                href={`https://api.whatsapp.com/send?phone=+966${item.Mobile}&text=Hello%2C%20I'm%20interested%20in%20your%20product`}
                                target="__blank"
                              >
                                {item.Mobile}
                              </a>
                            </td>
                            <td className="btn-box">
                              <button onClick={() => accountApproval(item)}>
                                قبول الحساب
                              </button>
                              <button onClick={() => rejectAccount(item)}>
                                رفض الحساب
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="no-data-message">
                          <td colSpan="2">لا توجد حسابات معلقه :(</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default TechnicalScreen;
