import React from "react";
import HeaderPage from "../HeaderPage";
import { team } from "./teamInfo";
import "./team.css";

const Team = () => {
  return (
    <section className="team">
      <HeaderPage title="الطاقم الفني">
        تعرف على طاقمنا المتخصص في صيانة التكييفات الصحراوية، جاهزون لخدمتكم بكل
        إحترافية في كل أعمال الصيانة والإصلاح.
      </HeaderPage>
      <div className="team-container container">
        {team.map(({ id, avatar, techName }) => {
          return (
            <div key={id} className="team-card">
              <div className="image-box">
                <img src={avatar} alt="ImageTech" />
              </div>
              <div className="text-box">
                <h3>{techName}</h3>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Team;
