import React, { useState } from "react";
import Logo from "../../assets/Opensource-01.png";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./login.css";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const submit = async (event) => {
    event.preventDefault();

    if (!username || !password) {
      setError("برجاء ادخال البيانات المطلوبه");
      return;
    }

    if (password.length < 8) {
      setError("يجب أن تكون كلمه المرور علي الاقل 8 احرف");
      return;
    }

    setLoading(true);

    try {
      const url = "https://aircon-api.basilalasadi.com/api/Authenticate/login";
      const response = await axios.post(url, {
        Username: username,
        Password: password,
      });
      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.UserRoles[0]);
        localStorage.setItem("UserId", response.data.UserId);
        localStorage.setItem(
          "MaintenanceAreaIds",
          response.data.MaintenanceAreaIds
        );
        toast.success("تم تسجيل الدخول بنجاح! ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        const role = localStorage.getItem("role");
        if (role === "Technician") navigate("/dashboard/inProgress");
        else navigate("/dashboard/newAppointment");
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error("خطأ أثناء تسجيل الدخول", error);
      setError("لقد حدث خطأ. الرجاء المحاوله لاحقا.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="login login-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>تسجيل الدخول</title>
        <link rel="canonical" href="https://generalhome.sa/login" />
      </Helmet>
      <div className="login-container container">
        <div className="logo">
            <img src={Logo} alt="Logo" />
        </div>
        <form onSubmit={submit}>
          <div className="input">
            <input
              type="text"
              id="username"
              name="name"
              placeholder="اسم المستخدم"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
          </div>
          <div className="input">
            <input
              type="password"
              id="password"
              name="password"
              placeholder="كلمه المرور"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
          {error && <p className="error">{error}</p>}
          <button disabled={loading}>
            {loading ? "جاري تسجيل الدخول..." : "تسجيل الدخول"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Login;
