import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "./updateSpare.css";

const isValidSparePart = (sparePart) => sparePart !== "";

const UpdateSpareParts = () => {
  const [sparePart, setSparePart] = useState([]);

  const params = useParams();
  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();

    const sparePartIsValid = isValidSparePart(sparePart);

    if (sparePartIsValid) {
      try {
        const url = `https://aircon-api.basilalasadi.com/api/AirCondMaintParts/${params.updateId}`;
        await axios.put(url, {
          Id: params.updateId,
          Name: sparePart,
        });
        toast.success("تم تعديل قطع الغيار", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/dashboard/addSpareParts");
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const getSparePartDetails = async () => {
    try {
      const response = await axios.get(
        `https://aircon-api.basilalasadi.com/api/AirCondMaintParts/${params.updateId}`
      );
      setSparePart(response.data.Name);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getSparePartDetails();
  }, []);

  return (
    <section className="updateSpare">
      <div className="updateSpare-container container">
        <div className="row">
          <div className="col-lg-12-md-12">
            <form onSubmit={submit} className="sparePartsForm">
              <div className="inputBox">
                <label htmlFor="acType">قطع الغيار :</label>
                <input
                  type="text"
                  placeholder="قطع الغيار"
                  value={sparePart}
                  onChange={(e) => setSparePart(e.target.value)}
                />
              </div>
              <button>تعديل</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpdateSpareParts;
