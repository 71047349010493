export const brands = [
  {
    id: 1,
    avatar: require("../../assets/logos/Dali.png"),
  },
  {
    id: 2,
    avatar: require("../../assets/logos/Morex.png"),
  },
  {
    id: 3,
    avatar: require("../../assets/logos/roxel.png"),
  },
  {
    id: 4,
    avatar: require("../../assets/logos/Vantum.png"),
  },
  {
    id: 5,
    avatar: require("../../assets/logos/alqaseem.png"),
  },
];
