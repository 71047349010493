import React from "react";
import ContactImage from "../../assets/Pictuers/contact.png";
import "./contact.css";

const Contact = () => {
  return (
    <section className="contact">
      <div className="container contact-container">
        <div className="row">
          <div className="col-lg-4">
            <div className="contact-right">
              <h1>تواصل معنا</h1>
              <form>
                <div className="input-box">
                  <input type="email" placeholder="البريد الإلكتروني" />
                </div>
                <div className="textarea-box">
                  <textarea placeholder="محتوي الرساله"></textarea>
                </div>
                <div className="button-box">
                  <button type="submit">إرسال</button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="contactleft">
              <img src={ContactImage} alt="ContactImage" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
