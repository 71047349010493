import axios from "axios";
import Swal from "sweetalert2";

const ErrorInterceptor = axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      Swal.fire({
        icon: "error",
        title: "غير مصرح!",
        text: "أنت غير مصرح لك بأداء هذا الإجراء.",
        confirmButtonColor: "#3085d6",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem = "token";
          window.location.href = "/login";
        }
      });
    } else if (status === 403) {
      Swal.fire({
        icon: "error",
        title: "ممنوع!",
        text: "غير مسموح لك بالدخول الي هذه الصفحه!",
        confirmButtonColor: "#3085d6",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem = "token";
          window.location.href = "/login";
        }
      });
    } else if (status === 404) {
      Swal.fire({
        icon: "warning",
        title: "غير موجود!",
        text: "لم يتم العثور على المورد المطلوب.",
        confirmButtonColor: "#3085d6",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "خطأ!",
        text: "لقد حدث خطأ. برجاء المحاوله لاحقا.",
        confirmButtonColor: "#3085d6",
      });
    }
    return Promise.reject(error);
  }
);

export default ErrorInterceptor;
