import React from "react";
import TestimonialsImage from "../../assets/Pictuers/testimonials.png";
import { testimonials } from "./textimonials";
import "./testimonils.css";

const Testimonils = () => {
  return (
    <section className="testimonils">
      <div className="overlay"></div>
      <div className="testimonils-container container">
        <div className="row">
          <div className="col-lg-6">
            <div className="testimonils-right">
              <div className="image-box">
                <img src={TestimonialsImage} alt="TestimonialsImage" />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="testimonils-left">
              <h1>رأي عملائنا</h1>
              <svg
                width="43"
                height="38"
                viewBox="0 0 43 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="path-1-outside-1_172_108"
                  maskUnits="userSpaceOnUse"
                  x="-4"
                  y="-2"
                  width="49"
                  height="42"
                  fill="black"
                >
                  <rect fill="white" x="-4" y="-2" width="49" height="42" />
                  <path d="M1.9375 0.5625H19.0625V10.4375C19.0625 20.9375 15.5625 29.9375 8.5625 37.4375H0.9375C6.10417 31.2708 9.16667 24.6875 10.125 17.6875H1.9375V0.5625ZM25.5 0.5625H42.5V10.4375C42.5 21.0625 39 30.0625 32 37.4375H24.5C29.7083 31.3125 32.7292 24.7292 33.5625 17.6875H25.5V0.5625Z" />
                </mask>
                <path
                  d="M1.9375 0.5625V-1.4375H-0.0625V0.5625H1.9375ZM19.0625 0.5625H21.0625V-1.4375H19.0625V0.5625ZM8.5625 37.4375V39.4375H9.4316L10.0246 38.8021L8.5625 37.4375ZM0.9375 37.4375L-0.595541 36.1531L-3.34737 39.4375H0.9375V37.4375ZM10.125 17.6875L12.1065 17.9588L12.4175 15.6875H10.125V17.6875ZM1.9375 17.6875H-0.0625V19.6875H1.9375V17.6875ZM25.5 0.5625V-1.4375H23.5V0.5625H25.5ZM42.5 0.5625H44.5V-1.4375H42.5V0.5625ZM32 37.4375V39.4375H32.8591L33.4506 38.8144L32 37.4375ZM24.5 37.4375L22.9764 36.1419L20.174 39.4375H24.5V37.4375ZM33.5625 17.6875L35.5486 17.9225L35.8131 15.6875H33.5625V17.6875ZM25.5 17.6875H23.5V19.6875H25.5V17.6875ZM1.9375 2.5625H19.0625V-1.4375H1.9375V2.5625ZM17.0625 0.5625V10.4375H21.0625V0.5625H17.0625ZM17.0625 10.4375C17.0625 20.4441 13.7458 28.9527 7.10039 36.0729L10.0246 38.8021C17.3792 30.9223 21.0625 21.4309 21.0625 10.4375H17.0625ZM8.5625 35.4375H0.9375V39.4375H8.5625V35.4375ZM2.47054 38.7219C7.84474 32.3076 11.0903 25.3817 12.1065 17.9588L8.14348 17.4162C7.24305 23.9933 4.36359 30.2341 -0.595541 36.1531L2.47054 38.7219ZM10.125 15.6875H1.9375V19.6875H10.125V15.6875ZM3.9375 17.6875V0.5625H-0.0625V17.6875H3.9375ZM25.5 2.5625H42.5V-1.4375H25.5V2.5625ZM40.5 0.5625V10.4375H44.5V0.5625H40.5ZM40.5 10.4375C40.5 20.5756 37.1795 29.0753 30.5494 36.0606L33.4506 38.8144C40.8205 31.0497 44.5 21.5494 44.5 10.4375H40.5ZM32 35.4375H24.5V39.4375H32V35.4375ZM26.0236 38.7331C31.452 32.3494 34.663 25.4064 35.5486 17.9225L31.5764 17.4525C30.7954 24.052 27.9647 30.2756 22.9764 36.1419L26.0236 38.7331ZM33.5625 15.6875H25.5V19.6875H33.5625V15.6875ZM27.5 17.6875V0.5625H23.5V17.6875H27.5Z"
                  fill="white"
                  mask="url(#path-1-outside-1_172_108)"
                />
              </svg>
              {testimonials.map(({ id, description, name }) => {
                return (
                  <div className="text-wrapper" key={id}>
                    <p>{description}</p>
                    <h5>{name}</h5>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonils;
