import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/Opensource-02.jpg";
import { useState, useEffect, useRef } from "react";
import "./navbar.css";

const Navbar = () => {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);
  const collapseRef = useRef(null);

  useEffect(() => {
    if (showNav) {
      collapseRef.current.style.height = `${collapseRef.current.scrollHeight}px`;
    } else {
      collapseRef.current.style.height = "0px";
    }
  }, [showNav]);

  const logout = () => {
    localStorage.clear();
    navigate("/login");
    setShowNav(false);
  };

  const hasTechnicianPermission = localStorage.getItem("role") === "Technician";
  const isTokenExsist = localStorage.getItem("token") === null;

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <Link className="image-box" to="/" onClick={() => setShowNav(false)}>
          <img src={Logo} alt="Company Logo" />
        </Link>
        <button
          className={`navbar-toggler ${showNav ? "" : "collapsed"}`}
          type="button"
          aria-controls="navbarNav"
          aria-expanded={showNav}
          aria-label="Toggle navigation"
          onClick={() => setShowNav(!showNav)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${showNav ? "show" : ""}`}
          id="navbarNav"
          ref={collapseRef}
        >
          {!localStorage.getItem("token") ? (
            <>
              <ul className="navbar-nav m-auto mt-0 mb-0">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    to="/"
                    onClick={() => setShowNav(false)}
                  >
                    الصفحه الرئيسيه
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    aria-disabled="true"
                    to="/appointment"
                    onClick={() => setShowNav(false)}
                  >
                    طلب صيانة
                  </Link>
                </li>
                {hasTechnicianPermission && !isTokenExsist && (
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      aria-disabled="true"
                      to="/dashboard/newAppointment"
                      onClick={() => setShowNav(false)}
                    >
                      لوحه التحكم
                    </Link>
                  </li>
                )}
              </ul>
              <Link
                className="button"
                to="/login"
                onClick={() => setShowNav(false)}
              >
                <button>
                  تسجيل الدخول
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00006 8L4.70717 11.2929C4.31664 11.6834 4.31664 12.3166 4.70717 12.7071L8.00006 16M5.00006 12L19.0001 12"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              </Link>
            </>
          ) : (
            <>
              <ul className="navbar-nav m-auto mt-0 mb-0">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    to="/"
                    onClick={() => setShowNav(false)}
                  >
                    الصفحه الرئيسيه
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() => setShowNav(false)}
                    className="nav-link"
                    aria-disabled="true"
                    to="/appointment"
                  >
                    طلب صيانة
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() => setShowNav(false)}
                    className="nav-link"
                    aria-disabled="true"
                    to="/dashboard/newAppointment"
                  >
                    لوحه التحكم
                  </Link>
                </li>
              </ul>
              <Link className="button" to="/login" onClick={logout}>
                <button>
                  تسجيل الخروج
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00006 8L4.70717 11.2929C4.31664 11.6834 4.31664 12.3166 4.70717 12.7071L8.00006 16M5.00006 12L19.0001 12"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              </Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
