import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import "./doneProjects.css";
import { useReactToPrint } from "react-to-print";

const DoneProjects = (props) => {
  const { pendingLength } = props;

  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [resetSearch, setResetSearch] = useState(false);

  const maxLength = 10;

  const getDoneProjects = async () => {
    try {
      var url =
        "https://aircon-api.basilalasadi.com/api/Reports/TechnicianRequests";
      await axios
        .post(url, {
          TechnicianId: params.doneProjectsId,
          statusId: 5,
          FromMaintenanceStartDate: fromDate,
          ToMaintenanceStartDate: toDate,
        })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // const handleDeleteRequest = async (item) => {
  //   Swal.fire({
  //     title: "هل أنت متأكد",
  //     text: "لن تستطيع استعاده الطلب !",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel!",
  //     reverseButtons: true,
  //     backdrop: `rgba(0,0,123,0.4)`,
  //     customClass: {
  //       container: "my-swal",
  //     },
  //   }).then(async (willDelete) => {
  //     if (willDelete.value) {
  //       try {
  //         const parsedData = JSON.parse(JSON.stringify(item));
  //         const requestId = parsedData.RequestId;
  //         await axios.delete(
  //           "https://aircon-api.basilalasadi.com/api/maintenance/DeleteMaintenanceRequest",
  //           {
  //             params: {
  //               requestId: requestId,
  //             },
  //           }
  //         );
  //         Swal.fire({
  //           title: "Deleted!",
  //           text: "The AC type has been deleted successfully",
  //           icon: "success",
  //           confirmButtonText: "Cool",
  //           timer: 2000,
  //         });
  //         getDoneProjects();
  //       } catch (error) {
  //         // Extract the error message from the response object
  //         let errorMessage;
  //         if (
  //           error.response &&
  //           error.response.data &&
  //           error.response.data.message
  //         ) {
  //           errorMessage = error.response.data.message;
  //           console.log(errorMessage);
  //         } else {
  //           errorMessage = "An unexpected error occurred";
  //           console.log(errorMessage);
  //         }
  //         // Display the error message using SweetAlert2
  //         Swal.fire({
  //           title: "Error!",
  //           text: errorMessage,
  //           icon: "error",
  //           confirmButtonText: "Try Again",
  //         });
  //         setError(error.response?.data || {});
  //       }
  //     } else {
  //       Swal.fire({
  //         title: "Cancelled",
  //         text: "Your AC type is safe :)",
  //         icon: "info",
  //         confirmButtonText: "OK",
  //       });
  //     }
  //   });
  // };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });

  const handleSearch = async (e) => {
    e.preventDefault();
    await getDoneProjects();
  };

  // const handleRemoveSearch = (e) => {
  //   setfromDate("");
  //   settoDate("");
  //   setResetSearch(true);
  //   e.preventDefault();
  // };

  useEffect(() => {
    getDoneProjects();
    if (resetSearch) {
      getDoneProjects();
      setResetSearch(false); // Reset the flag after calling getPagination
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [resetSearch]);

  return (
    <section className="newAppointment doneProjects mt-0">
      <Helmet>
        <meta charSet="utf-8" />
        <title>طلبات تم تنفيذها </title>
        <link
          rel="canonical"
          href="https://generalhome.sa/dashboard/newAppointment"
        />
      </Helmet>
      {loading ? (
        <div className="spinner-container">
          <ClipLoader
            className="loader"
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            color="#00b1ff"
          />
        </div>
      ) : (
        <>
          <div className="newAppointment-container">
            <div className="row">
              <div className="col-lg-2 col-md-2">
                <Sidebar pendingLength={pendingLength} />
              </div>
              <div
                className="col-lg-10 col-md-10 col-sm-10 col-xs-10 pt-4 col"
                ref={componentRef}
              >
                <div className="d-flex justify-content-between info">
                  <div className="title d-flex">
                    <h2 className="mx-2 mb-0">طلبات تم تنفيذها:</h2>
                    <h3>{data.length}</h3>
                  </div>
                  <div className="buttons">
                    <button className="printButton" onClick={handlePrint}>
                      طباعه التقرير
                    </button>
                  </div>
                </div>
                <div className="form-holder">
                  <h5>ما الذي تبحث عنه؟</h5>
                  <form onSubmit={handleSearch} className="mt-4">
                    <h5>من</h5>
                    <input
                      type="date"
                      onChange={(e) => setFromDate(e.target.value)}
                      value={fromDate}
                    />
                    <h5>إلى</h5>
                    <input
                      type="date"
                      onChange={(e) => setToDate(e.target.value)}
                      value={toDate}
                    />
                    <button disabled={loading} type="submit">
                      {loading ? "جاري البحث..." : "بحث"}
                    </button>
                  </form>
                </div>
                <div className="table-responsive-md">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="row">اسم العميل</th>
                        <th scope="row">رقم الهاتف</th>
                        <th scope="row">المنطقه</th>
                        <th scope="row">المدينه</th>
                        <th scope="row">المكيف</th>
                        <th scope="row">تاريخ الصيانه</th>
                        <th scope="row">اسم الفني</th>
                        <th scope="row" className="text-center">
                          التحكم
                        </th>
                      </tr>
                    </thead>
                    {data?.length > 0 ? (
                      <tbody>
                        {data.map((item) => (
                          <tr key={item.RequestId}>
                            <td>
                              {item.CustomerName &&
                              item.CustomerName.length > maxLength
                                ? `${item.CustomerName.substring(
                                    0,
                                    maxLength
                                  )}...`
                                : item.CustomerName}
                            </td>
                            <td>
                              <a
                                href={`https://api.whatsapp.com/send?phone=+966${item.PhoneNumber.replace(
                                  /[^\d+]/g,
                                  ""
                                )}&text=Hello%2C%20I'm%20interested%20in%20your%20product`}
                                target="__blank"
                              >
                                {item.PhoneNumber}
                              </a>
                            </td>
                            <td>
                              {item.Region && item.Region.length > maxLength
                                ? `${item.Region.substring(0, maxLength)}...`
                                : item.Region}
                            </td>
                            <td>
                              {item.City && item.City.length > maxLength
                                ? `${item.City.substring(0, maxLength)}...`
                                : item.City}
                            </td>
                            <td>
                              {item.ACType && item.ACType.length > maxLength
                                ? `${item.ACType.substring(0, maxLength)}....`
                                : item.ACType}
                            </td>
                            <td>{item.MaintenanceStartDate}</td>
                            <td>{item.TechnicianName}</td>
                            <td className="btn-box text-center">
                              {/* <button onClick={() => handleDeleteRequest(item)}>
                                مسح
                              </button> */}
                              <Link
                                className="link"
                                to={`/dashboard/inProgress/${item.RequestId}`}
                              >
                                <button>تفاصيل</button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="no-data-message">
                          <td colSpan="2">لا توجد بيانات لعرضها حاليا :(</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default DoneProjects;
