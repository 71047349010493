import React, { useRef } from "react";
import MaintainceState from "../../components/charts/maintainceState/MaintainceState";
import WarrantyChart from "../../components/charts/warrantyChart/WarrantyChart";
import TechnicianChart from "../../components/charts/technicianChart/TechnicianChart";
import AcTypesChart from "../../components/charts/acTypesChart/AcTypesChart";
import Sidebar from "../../components/sidebar/Sidebar";
import { useReactToPrint } from "react-to-print";
import "./chartsReport.css";
import RegionChart from "../../components/charts/regionChart/RegionChart";

const ChartsReport = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });
  return (
    <div className="chartsReport">
      <div className="chartsReport-container">
        <div className="row">
          <div className="col-lg-2">
            <Sidebar />
          </div>
          <div className="col-lg-10" ref={componentRef}>
            <div className="row">
              <div className="col-lg-12 col">
                <div className="chartsHeder d-flex justify-content-between mx-5">
                  <h2>الإحصائات</h2>
                  <button className="printButton" onClick={handlePrint}>
                    طباعه التقرير
                  </button>
                </div>
              </div>
              <div className="col-lg-6 mb-4 h-75">
                <WarrantyChart />
              </div>
              <div className="col-lg-6 mb-4 h-100">
                <MaintainceState />
              </div>
              <div className="col-lg-6 mb-4 h-100">
                <RegionChart />
                <TechnicianChart />
              </div>
              <div className="col-lg-6 mb-4 h-100">
                <AcTypesChart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartsReport;
