import React from "react";
import "./eidMubarak.css";

const EidMubarak = () => {
  return (
    <div className="eid">
            <div className="overlay"></div>
      <div className="eid-container container">
        <h1>عيد مبارك</h1>
        <p>
          نعتذر عملائنا الكرام عن استقبال الطلبات قبل اجازة عيد الاضحي بسبب
          الزحام.
        </p>
        <p>سوف يتم استقبال الطلبات مجددا بعد اجازة عيد الاضحي.</p>
      </div>
    </div>
  );
};

export default EidMubarak;
