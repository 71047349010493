import React from "react";

const HeaderPage = ({ title, children }) => {
  return (
    <article className="headline text-center">
      <h1>{title}</h1>
      <p>{children}</p>
    </article>
  );
};

export default HeaderPage;
