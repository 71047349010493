import React from "react";
import HeaderPage from "../HeaderPage";
import FeaturesImage1 from "../../assets/Pictuers/Creative thinking-amico.png";
import FeaturesImage2 from "../../assets/Pictuers/Product quality-amico.png";
import FeaturesImage3 from "../../assets/Pictuers/Manufacturing Process-rafiki.png";
import FeaturesImage4 from "../../assets/Pictuers/Call center-cuate.png";
import "./features.css";

const Features = () => {
  return (
    <section className="features">
      <HeaderPage title="المزايا"></HeaderPage>
      <div className="features-container container">
        <div className="row">
          <div className="col-lg-6 col-md-12 column">
            <div className="features-right">
              <div className="text-box">
                <h2>تقنيات متطورة للبيئات الصحراوية</h2>
                <p>
                  تتميز مكيفات جنرال هوم _ روكسل الصحراويه بتقنيات مبتكره تمنح
                  الراحه في ظروف الصحاري القاسيه, فهي تعتمد علي احدث التقنيات
                  لضمان تبريد فعال وتنقيه الهواء من الغبار والشوائب , مما يجعلها
                  خيارا مثاليا.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 column">
            <div className="features-left">
              <div className="image-box">
                <img src={FeaturesImage1} alt="FeaturesImage" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 column">
            <div className="features-left">
              <div className="image-box">
                <img src={FeaturesImage2} alt="FeaturesImage" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 column">
            <div className="features-right">
              <div className="text-box">
                <h2>كفاءة الطاقة وتوفير التكاليف</h2>
                <p>
                  تتميز مكيفات جنرال هوم _ روكسل بكفاءة الطاقه وتوفير التكاليف
                  حيث تعمل علي طاقه مناسبه بخاصيه DC_INVERTER اقل استهلاكا
                  للتكاليف والكهرباء
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 column">
            <div className="features-right">
              <div className="text-box">
                <h2>جودة التصنيع والمواد المتينة</h2>
                <p>
                  تتميز مكيفات جنرال هوم _ روكسل بجودة تصنيع عاليه المقاومه
                  للشمس والعوامل الخارجيه حيث تمتاز بهيكل مصنوع من البلاسيك
                  المقاوم لاشعه الشمس , ومواد غير قابله للصدأ , وفلاتر لتنقيه
                  الهواء من الغبار
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 column">
            <div className="features-left">
              <div className="image-box">
                <img src={FeaturesImage3} alt="FeaturesImage" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 column">
            <div className="features-left">
              <div className="image-box">
                <img src={FeaturesImage4} alt="FeaturesImage" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 column">
            <div className="features-right">
              <div className="text-box">
                <h2>خدمة ما بعد البيع والصيانة</h2>
                <p>
                  تتميز مكيفات جنرال هوم _ روكسل بفريق صيانه ذو كفاءه عاليه
                  مختصين في صيانه المكيفات الصحراويه ومزودين بجميع المعدات
                  والادوات وقطع الغيار لاصلاح الاعطال باحترافيه
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
