import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { useReactToPrint } from "react-to-print";
import "./cancelledRequests.css";

const CancelledRequests = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedForDelete, setSelectedForDelete] = useState([]);
  const [error, setError] = useState(null);

  const url = `https://aircon-api.basilalasadi.com/api/maintenance/GetCancelRequests`;

  const maxLength = 10;
  const maxLengthCity = 6;

  const getAllAppointments = async () => {
    try {
      const maintenanceAreaIdsString =
        localStorage.getItem("MaintenanceAreaIds");
      const maintenanceAreaIds = maintenanceAreaIdsString
        .split(",")
        .map(Number);
      const response = await axios.post(url, {
        maintenanceAreaIds: maintenanceAreaIds,
      });
      setAppointments(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (RequestId) => {
    const newSelected = [...selectedForDelete];
    if (newSelected.includes(RequestId)) {
      // Remove from selected
      const index = newSelected.indexOf(RequestId);
      newSelected.splice(index, 1);
    } else {
      // Add to selected
      newSelected.push(RequestId);
    }
    setSelectedForDelete(newSelected);
  };

  const handleDelete = async () => {
    Swal.fire({
      title: "هل أنت متأكد",
      text: "لن تستطيع استعاده الطلب !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
      backdrop: `rgba(0,0,123,0.4)`,
      customClass: {
        container: "my-swal",
      },
    }).then(async (willDelete) => {
      if (willDelete.value) {
        if (selectedForDelete.length === 0) {
          return;
        }
        try {
          await axios.delete(
            `https://aircon-api.basilalasadi.com/api/maintenance/DeleteMultipleMaintenanceRequest/${selectedForDelete.join(
              ","
            )}`
          );
          Swal.fire({
            title: "Deleted!",
            text: "The Request has been deleted successfully",
            icon: "success",
            confirmButtonText: "Cool",
            timer: 2000,
          });
          getAllAppointments();
        } catch (error) {
          // Extract the error message from the response object
          let errorMessage;
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.status === false
          ) {
            errorMessage = error.response.data.message;
            console.log(errorMessage);
          } else {
            errorMessage = "An unexpected error occurred";
            console.log(errorMessage);
          }
          // Display the error message using SweetAlert2
          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            confirmButtonText: "Try Again",
          });
          setError(error.response?.data || {});
        }
      } else {
        Swal.fire({
          title: "Cancelled",
          text: "Your AC type is safe :)",
          icon: "info",
          confirmButtonText: "OK",
        });
      }
    });
    setSelectedForDelete([]);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });

  useEffect(() => {
    getAllAppointments();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <section className="newAppointment finished mt-0">
      <Helmet>
        <meta charSet="utf-8" />
        <title>الطلبات الملغيه</title>
        <link
          rel="canonical"
          href="https://generalhome.sa/dashboard/inProgress"
        />
      </Helmet>
      {loading ? (
        <div className="spinner-container">
          <ClipLoader
            className="loader"
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            color="#00b1ff"
          />
        </div>
      ) : (
        <div className="newAppointment-container">
          <div className="row">
            <div className="col-lg-2 col-md-2 px-0">
              <Sidebar />
            </div>
            <div
              className="col-lg-10 col-md-10 col-sm-10 col-xs-10 pt-4 col"
              ref={componentRef}
            >
              <div className="d-flex justify-content-between info">
                <div className="title d-flex">
                  <h2 className="mx-2 mb-4">الطلبات الملغيه</h2>
                  <h3 className="mb-3">{appointments.length}</h3>
                </div>
                <div className="buttons">
                  <button
                    className="mx-4"
                    hidden={selectedForDelete.length === 0}
                    onClick={handleDelete}
                  >
                    مسح
                  </button>
                  <button className="printButton" onClick={handlePrint}>
                    طباعه التقرير
                  </button>
                </div>
              </div>
              <div className="table-responsive-sm table-responsive-md">
                <table className="table table-striped table-responsive">
                  <thead>
                    <tr>
                      <th scope="row"></th>
                      <th scope="row">رقم الطلب</th>
                      <th scope="row">اسم العميل</th>
                      <th scope="row">رقم الهاتف</th>
                      <th scope="row">المدينه</th>
                      <th scope="row">نوع المكيف</th>
                      <th scope="row">تاريخ الصيانه</th>
                      <th scope="row">الفني المسؤول</th>
                      <th scope="row" className="text-center">
                        التحكم
                      </th>
                    </tr>
                  </thead>
                  {appointments?.length > 0 ? (
                    <tbody>
                      {appointments.map((appointment) => (
                        <tr key={appointment.RequestId}>
                          <td>
                            <input
                              className="custom-checkbox"
                              type="checkbox"
                              checked={selectedForDelete.includes(
                                appointment.RequestId
                              )}
                              onChange={() =>
                                handleCheckboxChange(appointment.RequestId)
                              }
                            />
                          </td>
                          <td>{appointment.FormattedRequestNumber}</td>
                          <td>
                            {appointment.CustomerName &&
                            appointment.CustomerName.length > maxLength
                              ? `${appointment.CustomerName.substring(
                                  0,
                                  maxLength
                                )}...`
                              : appointment.CustomerName}
                          </td>
                          <td>
                            <a
                              href={`https://api.whatsapp.com/send?phone=+966${appointment.PhoneNumber.replace(
                                /[^\d+]/g,
                                ""
                              )}&text=Hello%2C%20I'm%20interested%20in%20your%20product`}
                              target="__blank"
                            >
                              {appointment.PhoneNumber}
                            </a>
                          </td>
                          <td>
                            {appointment.City &&
                            appointment.City.length > maxLengthCity
                              ? `${appointment.City.substring(
                                  0,
                                  maxLengthCity
                                )}...`
                              : appointment.City}
                          </td>
                          <td>
                            {appointment.ACType &&
                            appointment.ACType.length > maxLength
                              ? `${appointment.ACType.substring(
                                  0,
                                  maxLength
                                )}...`
                              : appointment.ACType}
                          </td>
                          <td>{appointment.MaintenanceStartDate}</td>
                          <td>{appointment.TechnicianName}</td>
                          <td className="btn-box">
                            <Link
                              to={`/dashboard/inProgress/${appointment.RequestId}`}
                            >
                              <button>تفاصيل</button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr className="no-data-message">
                        <td colSpan="2">لا توجد بيانات لعرضها حاليا :(</td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default CancelledRequests;
