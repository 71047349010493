import React from "react";
import FooterLogo from "../../assets/Opensource-02.jpg";
import Facebook from "../../assets/Pictuers/Facebook.svg";
import SnapChat from "../../assets/Pictuers/Snapchat (1).svg";
import Instagram from "../../assets/Pictuers/Instagram.png";
import Twitter from "../../assets/Pictuers/TwitterX.svg";
import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container container">
        <div className="image-box">
          <img src={FooterLogo} alt="" />
        </div>
        <div className="fastNav">
          <h5>الوصول السريع</h5>
          <ul>
            <li>
              <Link to="/appointment">طلب الصيانة</Link>
            </li>
            <li>المزايا</li>
            <li>ما يميزنا</li>
          </ul>
        </div>
        <div className="importantLinks">
          <h5>روابط تهمك</h5>
          <ul>
            <li>روابط تهمك</li>
            <li>رأي عملائنا</li>
            <li>تواصل معنا</li>
          </ul>
        </div>
        <div className="contactLinks">
          <h5>الوصول السريع</h5>
          <ul>
            <li>hanyamian@gmail.com</li>
            <li>055124232</li>
          </ul>
        </div>
        <div className="socialMedia">
          <ul>
            <li>
              <a href="https://www.snapchat.com/add/alyahya1">
                <img src={SnapChat} alt="snapChat" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/generaihome_roxel">
                <img src={Instagram} alt="Instagram" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/GeneralHome3">
                <img src={Twitter} alt="Twitter" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/profile.php?id=100041785110645">
                <img src={Facebook} alt="Facebook" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="copyright container">
        <p>2024 Hive Agency - All Rights Reserved ©</p>
      </div>
    </footer>
  );
};

export default Footer;
