import React from "react";
import { Helmet } from "react-helmet";
import Hero from "../../components/hero/Hero";
import Features from "../../components/features/Features";
import AboutUs from "../../components/aboutus/AboutUs";
import Team from "../../components/team/Team";
import Brands from "../../components/brands/Brands";
import Contact from "../../components/contact/Contact";
import Testimonils from "../../components/testimonials/Testimonils";
import Footer from "../../components/footer/Footer";

const Home = () => {
  return (
    <div className="home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>صيانه جنرال هوم</title>
        <link rel="canonical" href="https://generalhome.sa" />
      </Helmet>
      <Hero />
      <Features />
      {/* <AboutUs /> */}
      <Team />
      {/* <Brands /> */}
      <Testimonils />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
