import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import "./dashboard.css";

const Dashboard = () => {
  return (
    <section className="dashboard">
      <div className="dashboard-container">
        <div className="row">
          <div className="col-lg-12 col-md-12"></div>
        </div>
      </div>
      <Outlet />
    </section>
  );
};

export default Dashboard;
