import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import Sidebar from "../sidebar/Sidebar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import "./waiting.css";
import { useReactToPrint } from "react-to-print";

const ApprovedList = (props) => {
  const { pendingLength } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [data, setData] = useState([]);
  const [modifiedData, setModifiedData] = useState([]);

  const maxLength = 10;

  const getAllAppointments = async () => {
    try {
      const maintenanceAreaIdsString =
        localStorage.getItem("MaintenanceAreaIds");
      const maintenanceAreaIds = maintenanceAreaIdsString
        .split(",")
        .map(Number);
      const url = `https://aircon-api.basilalasadi.com/api/maintenance/GetApprovedRequests`;
      const response = await axios.post(url, {
        maintenanceAreaIds: maintenanceAreaIds,
      });
      setModifiedData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getApprovedTechnicians = async () => {
    try {
      const maintenanceAreaIdsString =
        localStorage.getItem("MaintenanceAreaIds");
      const maintenanceAreaIds = maintenanceAreaIdsString
        .split(",")
        .map(Number);
      const url = `https://aircon-api.basilalasadi.com/api/maintenance/GetApprovedTechnicians`;
      const res = await axios.post(url, {
        maintenanceAreaIds: maintenanceAreaIds,
      });
      setData(res.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMoveData = async (item, index) => {
    const selectedDropdownOption = document.querySelector(
      `#tech-dropdown-${index}`
    ).value;
    const matchingItem = modifiedData.find(
      (el) => el.RequestId === item.RequestId
    );
    if (
      (matchingItem && matchingItem.IsDateModified) ||
      selectedDropdownOption !== "none"
    ) {
      let editedDate = "";
      if (matchingItem && matchingItem.IsDateModified) {
        editedDate = matchingItem.MaintenanceStartDate;
      }
      try {
        const parsedData = JSON.parse(JSON.stringify(item));
        const requestId = parsedData.RequestId;
        await axios.post(
          "https://aircon-api.basilalasadi.com/api/maintenance/AssigningMaintananceStartDateAndTechnicians",
          {
            RequestId: requestId,
            MaintenanceStartDate: editedDate,
            SelectedTechnicianId: selectedDropdownOption,
          }
        );
        toast.success("تم جدوله الطلب بنجاح", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setModifiedData(
          modifiedData.filter((d) => d.RequestId !== item.RequestId)
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.warning("لم يتم إجراء أي تغييرات.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const deleteRequest = async (item) => {
    Swal.fire({
      title: "هل أنت متأكد",
      text: "لن تستطيع استعاده الطلب !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
      backdrop: `rgba(0,0,123,0.4)`,
      customClass: {
        container: "my-swal",
      },
    }).then(async (willDelete) => {
      if (willDelete.value) {
        try {
          const parsedData = JSON.parse(JSON.stringify(item));
          const requestId = parsedData.RequestId;
          await axios.delete(
            "https://aircon-api.basilalasadi.com/api/maintenance/DeleteMaintenanceRequest",
            {
              params: {
                requestId: requestId,
              },
            }
          );
          Swal.fire({
            title: "Deleted!",
            text: "The AC type has been deleted successfully",
            icon: "success",
            confirmButtonText: "Cool",
            timer: 2000,
          });
          getAllAppointments();
        } catch (error) {
          let errorMessage;
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
            console.log(errorMessage);
          } else {
            errorMessage = "An unexpected error occurred";
            console.log(errorMessage);
          }
          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            confirmButtonText: "Try Again",
          });
          setError(error.response?.data || {});
        }
      } else {
        Swal.fire({
          title: "Cancelled",
          text: "Your AC type is safe :)",
          icon: "info",
          confirmButtonText: "OK",
        });
      }
    });
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });

  useEffect(() => {
    getAllAppointments();
    getApprovedTechnicians();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <section className="newAppointment waitingList mt-0">
      <Helmet>
        <meta charSet="utf-8" />
        <title>قائمه الانتظار</title>
        <link
          rel="canonical"
          href="https://generalhome.sa/dashboard/approvesList"
        />
      </Helmet>
      {loading ? (
        <div className="spinner-container">
          <ClipLoader
            className="loader"
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            color="#00b1ff"
          />
        </div>
      ) : (
        <div className="newAppointment-container ">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
              <Sidebar pendingLength={pendingLength} />
            </div>
            <div
              className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col pt-4"
              ref={componentRef}
            >
              <div className="head d-flex justify-content-between">
                <h2>قائمه الانتظار:</h2>
                <button className="printButton" onClick={handlePrint}>
                  طباعه التقرير
                </button>
              </div>
              <div className="table-responsive-sm">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="row">اسم العميل</th>
                      <th scope="row">رقم الهاتف</th>
                      <th scope="row">المدينه</th>
                      <th scope="row">نوع المكيف</th>
                      <th scope="row">تاريخ الصيانه</th>
                      <th scope="row">الفني المسؤول</th>
                      <th className="text-center" scope="row">
                        التحكم
                      </th>
                    </tr>
                  </thead>
                  {modifiedData?.length > 0 ? (
                    <tbody>
                      {modifiedData.map((item, index) => (
                        <tr key={item.RequestId}>
                          <td>{item.CustomerName}</td>
                          <td>
                            <a
                              href={`https://api.whatsapp.com/send?phone=+966${item.PhoneNumber.replace(
                                /[^\d+]/g,
                                ""
                              )}&text=Hello%2C%20I'm%20interested%20in%20your%20product`}
                              target="__blank"
                            >
                              {item.PhoneNumber}
                            </a>
                          </td>
                          <td>{item.City}</td>
                          <td>
                            {item.ACType && item.ACType.length > maxLength
                              ? `${item.ACType.substring(0, maxLength)}....`
                              : item.ACType}
                          </td>{" "}
                          <td>
                            {item.IsDateModified ? (
                              <input
                                type="date"
                                min={new Date().toISOString().slice(0, 16)}
                                value={item.MaintenanceStartDate || ""}
                                onChange={(event) => {
                                  setModifiedData(
                                    modifiedData.map((el) =>
                                      el.RequestId === item.RequestId
                                        ? {
                                            ...el,
                                            MaintenanceStartDate:
                                              event.target.value,
                                            IsDateModified: true,
                                          }
                                        : el
                                    )
                                  );
                                }}
                              />
                            ) : (
                              <p>{item.MaintenanceStartDate}</p>
                            )}
                          </td>
                          {data?.length > 0 ? (
                            <td>
                              <div className="selection-box mt-0">
                                <select
                                  className="custom-select w-100"
                                  id={`tech-dropdown-${index}`}
                                >
                                  <option>تحديد الفني</option>
                                  {data.map((item) => (
                                    <option
                                      value={item.TechnicianId}
                                      key={item.TechnicId}
                                    >
                                      {item.Username}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </td>
                          ) : (
                            <tbody>
                              <tr className="no-data-message">
                                <td colSpan="2">لا يوجد فنيون :(</td>
                              </tr>
                            </tbody>
                          )}
                          <td className="waitingBtn">
                            <div>
                              <button
                                onClick={() => {
                                  setModifiedData(
                                    modifiedData.map((el) =>
                                      el.RequestId === item.RequestId
                                        ? {
                                            ...el,
                                            IsDateModified: !el.IsDateModified,
                                          }
                                        : el
                                    )
                                  );
                                }}
                              >
                                التاريخ
                              </button>
                              <button
                                className="link m-1"
                                onClick={() => {
                                  handleMoveData(item, index);
                                }}
                              >
                                جدوله
                              </button>
                            </div>
                            <div>
                              <Link
                                className="link"
                                to={`/dashboard/inProgress/${item.RequestId}`}
                              >
                                <button>تفاصيل</button>
                              </Link>
                              <button
                                className="link m-1"
                                onClick={() => deleteRequest(item)}
                              >
                                مسح
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr className="no-data-message">
                        <td colSpan="2">لا توجد بيانات لعرضها حاليا :(</td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ApprovedList;
